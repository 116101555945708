import axios from 'axios';

const baseUrl = '/api/tracks';
let token = null;

const setToken = newToken => {
  token = `bearer ${newToken}`;
};

const checkRadiomanTracks = async searchParams => {
  const {
    date,
    endTime,
    startTime,
    report_id,
    sortable_rank_start
  } = searchParams;

  const config = {
    headers: { Authorization: token }
  };

  const response = await axios.get(
    `${baseUrl}/radioman?date=${date}&endTime=${endTime}&startTime=${startTime}&report_id=${report_id}&sortable_rank_start=${sortable_rank_start}`,
    config
  );

  return response.data;
};

// add new track and save it to a report
const addNewTrack = async trackToAdd => {
  const config = {
    headers: { Authorization: token }
  };
  const response = await axios.post(
    `${baseUrl}/addandreport`,
    trackToAdd,
    config
  );
  return response.data;
};

// add new track without saving to report
const addTrackToDb = async trackToAdd => {
  const config = {
    headers: { Authorization: token }
  };
  const response = await axios.post(`${baseUrl}/addtodb`, trackToAdd, config);
  return response.data;
};

// add track to album without saving to a report
const addTrackToAlbum = async trackToAdd => {
  const config = {
    headers: { Authorization: token }
  };
  const response = await axios.post(
    `${baseUrl}/addtracktoalbum`,
    trackToAdd,
    config
  );
  return response.data;
};

const updateTrack = async trackToUpdate => {
  const config = {
    headers: { Authorization: token }
  };
  const response = await axios.put(baseUrl, trackToUpdate, config);
  return response.data;
};

const updateAlbumId = async albumToUpdate => {
  const config = {
    headers: { Authorization: token }
  };
  const response = await axios.put(
    '/api/tracks/updatealbum',
    albumToUpdate,
    config
  );
  return response.data;
};

const updateArtistId = async artistToUpdate => {
  const config = {
    headers: { Authorization: token }
  };
  const response = await axios.put(
    '/api/tracks/updateartist',
    artistToUpdate,
    config
  );
  return response.data;
};

const getOneTrack = async id => {
  const config = {
    headers: { Authorization: token }
  };
  const response = await axios.get(`${baseUrl}/details/${id}`, config);
  return response.data;
};

const getOneTrackHistory = async id => {
  const config = {
    headers: { Authorization: token }
  };
  const response = await axios.get(`${baseUrl}/history/${id}`, config);
  return response.data;
};

export default {
  setToken,
  checkRadiomanTracks,
  addNewTrack,
  addTrackToAlbum,
  updateTrack,
  getOneTrack,
  getOneTrackHistory,
  updateAlbumId,
  updateArtistId,
  addTrackToDb
};
